<template>
    <div>
        <div class="bottomNav" v-show="showNav">
            <div class="sceneNav" >
                    <div :class="{active:activeStatus(item.name)}" v-for="item,k in navList" :key="k"  @click="operateBottomNav(item.name)">
                        <i :class="{active:activeStatus(item.name)}" class="iconfont" v-html="item.icon"></i>
                        <span>{{item.name}}</span>
                    </div>
                    <div @click="operateBottomNav('关闭')">
                        <i class="iconfont">&#xe601;</i>
                        <span>关闭</span>
                    </div>
            </div>
            <div class="bottomImgList" v-show="showChooseTab">
                    <div class="swiper mySwiper">
                        <div class="swiper-wrapper">
                            <div  v-for="item,k in switchTabListShow" :key="k" @click="changeScene(k+1)" class="swiper-slide">
                                <img :src="item">
                            </div>
                
                        </div>
                        <div class="swiper-pagination"></div>
                    </div>
            </div>
        </div>
        <div class="bottomNavButton">
            <div @click="operateBottomNav('全屏')">
                <i class="iconfont">&#xe649;</i>
            </div>
            <div @click="operateBottomNav('帮助')">
                <i class="iconfont">&#xe619;</i>
            </div>
            <div @click="operateBottomNav('声音')">
                <i :class="{active:activeStatus('声音')}"  class="iconfont">&#xe644;</i>
            </div>
            <div @click="operateBottomNav('视角切换')">
                <i class="iconfont">&#xe60d;</i>
            </div>
            <div @click="operateBottomNav('主页')"  >
                <i class="iconfont">&#xe61a;</i>
            </div>
           <div  class="wechat-share" @click="operateBottomNav('微信')" v-show="wechatShow">
             <div class="share-bless">分享祝福</div>
            <img class="libao" src="../../public/zqlb.png" alt="">
            </div>
            <div @click="wanderStart(5)" v-show="testPage">
                <i class="iconfont">&#xe624;</i>
            </div>

            <div  @click="operateBottomNav('打开')" v-show="testPage">
                <i :class="{active:showNav}" class="iconfont">&#xe600;</i>
            </div>

            <div @click="praise()" v-show="praiseShow">
                <i :class="{active:praiseClass}" class="iconfont">&#xe62a;</i>
            </div>
        </div>

        <div class="bottomWander" v-show="showWander">
            <div class="sceneNav" >
                <div @click="wanderStart(1)">
                    <i class="iconfont">&#xe624;</i>
                    <span>继续漫游</span>
                </div>
                <div @click="wanderStart(2)">
                    <i class="iconfont">&#xe71d;</i>
                    <span>结束漫游</span>
                </div>
                <div @click="wanderStart(3)">
                    <i class="iconfont">&#xe63b;</i>
                    <span>下个场景</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {  getExhiUlikeInfo,onUlike } from "@/js/api.js";
// import { Swiper } from 'swiper';
import {getQueryVariable} from "@/js/tool";
// import 'swiper/swiper-bundle.min.css';
export default {
    name: "bottomNav",
    props:{
        tem_id:Number
    },
    data() {
        return {
            praiseClass:false,
            praiseShow:false,
            wechatShow:false,
            showWander:false,
            testPage:true,
            nowTab: '',
            isMusic: true,
            showNav:false,
            showChooseTab:false,
            navList: [{
                "name":"导航",
                "icon":"&#xe93b;"
            },
            {
                "name":"声音",
                "icon":"&#xe644;"
            },{
                "name":"材质",
                "icon":"&#xe647;"
            }],
            switchTabListShow:[],
            switchTabList:{
                44:{
                    "mat":[
                    "/template/44/materialPng/几何灰.JPEG",
                    "/template/44/materialPng/金丝玉.JPEG",
                    "/template/44/materialPng/金樱桃.JPEG",
                    "/template/44/materialPng/缥白.JPEG"
                ],
                    "changeScene":[
                    "/template/44/navPng/1.png",
                    "/template/44/navPng/2.png",
                    "/template/44/navPng/3.png",
                    "/template/44/navPng/4.png",
                    "/template/44/navPng/5.png",
                ]},
            },
            mch_id:getQueryVariable("mch_id") || 0,
            type:0
        };
    },
    created() {
        this.setPage();
        if(this.tem_id == 70){
            this.wechatShow = true
        }
        this.getPraiseStatus();
    },
    mounted(){
        // var swiper = new Swiper(".mySwiper", {
        //         slidesPerView: 'auto',
        //         spaceBetween: 0,
        // });
        window.setMaterial = this.setMaterial
        window.wanderStart = this.wanderStart
        window.operateBottomNav = this.operateBottomNav
    },
    methods: {
        praise(){
            let tem_id_dy = localStorage.getItem('tem_id_dy');
            let token = localStorage.getItem('token');
            let tem_id = this.tem_id
            let mch_id = this.mch_id
            if(tem_id_dy && token){
                let praiseClass = this.praiseClass
                if(praiseClass){
                    this.type = 2
                }
                if(!praiseClass){
                    this.type = 1
                }
                onUlike({
                    token:token,
                    tem_id_dy: tem_id_dy,
                    tem_id:tem_id,
                    mch_id:mch_id,
                    type:this.type
                }).then(res => {
                    if(res.data.error == 0){
                        console.log(res)
                        this.praiseClass = !this.praiseClass
                    }
                })
            }
        },
        getPraiseStatus(){
            let tem_id_dy = localStorage.getItem('tem_id_dy');
            let token = localStorage.getItem('token');

            if(tem_id_dy && token){
                getExhiUlikeInfo({
                    token:token,
                    tem_id_dy: tem_id_dy
                }).then(res => {
                    if(res.data.error == 0){
                        this.praiseShow = true
                        if(res.data.type == 2){
                            this.praiseClass = false
                        }
                        if(res.data.type == 1){
                            this.praiseClass = true
                        }
                    }
                })
            }
        },
        wanderStart(type){
            if(this.tem_id != 44){
                this.showNav = false
                this.showWander = false
                return;
            }
            if(type == 1){
                this.showNav = false
                this.showWander = false
            }
            if(type == 2){
                //点击结束漫游
                window.game.cameraControl.stopRoaming();
                this.showNav = false
                this.showWander = false

                
            }
            if(type == 3){
                //下个场景
              window.game.cameraControl && window.game.cameraControl.playAnimByIndex(window.game.cameraControl.currentIndex+1);
            }
            if(type == 4){
                //弹出漫游框
                this.showNav = false
                this.showWander = true
            }
            if(type == 5){
                //自动开始上一次结束的漫游
                console.log('click')
                window.game.cameraControl && window.game.cameraControl.playAnimByIndex(Number(window.game.cameraControl.currentIndex));
                this.operateBottomNav('关闭')
            }

        },
        setMaterial(str){
            if(str == '进入'){
                console.log('进入')
                //触发材质
                this.nowTab = '材质'
                this.showChooseTab = true
                this.switchTabListShow = this.switchTabList[this.tem_id].mat
                this.showNav = true
            }else{
                console.log('退出')
                this.nowTab = ''
                this.showChooseTab = false
                this.switchTabListShow = []
            }

        },
        setPage(){
            console.log(this.tem_id)
            if(this.tem_id != 44){
                this.testPage = false
                this.showNav = false
                this.operateBottomNav('关闭');
            }
        },
        activeStatus(str){
           if(str == "声音"){
                return this.isMusic;
           }else{
                return this.nowTab == str;
           }
        },
        setInitStatus(str){
            if(str == '导航'){
                // this.showChooseTab = true
                this.nowTab = '导航'
                this.switchTabListShow = this.switchTabList[this.tem_id].changeScene
            }
        },
        operateBottomNav(str){
            console.log(this.nowTab)
            if(str == '主页'){
                toWxappHomePage();
            }
            if(str == '微信'){
                toWxapp();
            }
            if(str == '视角切换'){

                window.game.cameraControl.switchCameraPerson();
            }
            if(str == '关闭'){

                this.showNav = false
            }
            if(str == '继续'){
                window.game.cameraControl.continueRoaming();
                
            }
            if(str == '导航'){
                if(this.nowTab != '导航'){
                    this.nowTab = '导航'
                    this.showChooseTab = true
                    this.switchTabListShow = this.switchTabList[this.tem_id].changeScene
                }else{
                    //第一次进入为导航
                    if(!this.showChooseTab){
                        this.showChooseTab = true
                    }else{
                        this.showChooseTab = false
                        this.nowTab = ''
                        this.switchTabListShow = []
                    }

                }
                window.game.cameraControl.setRoaming(true);

            }
             if(str == '打开'){
                this.showWander = false
                if(this.showNav){
                    this.showNav = false

                }else{
                    this.showNav = true
                    if(this.nowTab != ''){
                        if(this.nowTab == '导航' || this.nowTab == '传送'){
                            this.showChooseTab = true;
                            this.switchTabListShow = this.switchTabList[this.tem_id].changeScene
                        }
                        if(this.nowTab == '材质'){
                            this.showChooseTab = true;
                            this.switchTabListShow = this.switchTabList[this.tem_id].mat
                        }
                    }
                }

            }
            if(str == '全屏'){
                switchFullScreen();
            }
            if(str == '帮助'){
                this.$emit('showHelpTab');
            }
            if(str == '声音'){
                switchMusic();
                this.isMusic = !this.isMusic
                window.game.cameraControl.playerCamera.switchSpeed();
            }
            if(str == '传送'){
                // console.log('传送')
                if(this.nowTab != '传送'){
                this.nowTab = '传送'
                this.showChooseTab = true
                this.switchTabListShow = this.switchTabList[this.tem_id].changeScene
                }else{
                    this.showChooseTab = false
                    this.nowTab = ''
                    this.switchTabListShow = []
                }
                window.game.cameraControl.setRoaming(false);
                // this.showChooseTab = !this.showChooseTab
            }
            if(str == '材质'){
     
                if(this.nowTab != '材质'){
                    this.nowTab = '材质'
                    this.showChooseTab = true
                    this.switchTabListShow = this.switchTabList[this.tem_id].mat
                }else{
                    this.showChooseTab = false
                    this.switchTabListShow = []
                    this.nowTab = ''
                }
            }
        },
        changeScene(index){
            if(this.nowTab != '材质'){
            switch(index){
                case 1:
                    window.game.cameraControl.playAnimByIndex(1);
                    break;
                case 2:
                    window.game.cameraControl.playAnimByIndex(2);
                    break;
                case 3:
                    window.game.cameraControl.playAnimByIndex(3);
                    break;
                case 4:
                    window.game.cameraControl.playAnimByIndex(4);
                    break;
                case 5:
                    window.game.cameraControl.playAnimByIndex(5);
                    break;
            }
        }else{
            switch(index){
                case 1:
                    window.game.replaceMaterial("/template/44/materialPng/几何灰.JPEG");
                    break;
                case 2:
                    window.game.replaceMaterial("/template/44/materialPng/金丝玉.JPEG");
                    break;
                case 3:
                    window.game.replaceMaterial("/template/44/materialPng/金樱桃.JPEG");
                    break;
                case 4:
                    window.game.replaceMaterial("/template/44/materialPng/缥白.JPEG");
                    break;
            }
        }
    }
},
    watch:{
        showNav(n,o){
            if(n == false){
                this.showChooseTab = false
            }
        },
        nowTab(n,o){
            console.log(n)
        },
        // showWander(n,o){
        //     if(n){
        //         setTimeout(()=>{
        //             this.showWander = false
        //         },5000)
                
        //     }
        // }
    }
};
</script>
<!-- 两种样式 768px为边界线-->
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 /* @import url('../../node_module/swiper/swiper-bundle.min.css'); */
.active{
    color:#09c9ff !important;
}
::-webkit-scrollbar {
  display: none;
}
* {
  padding: 0;
  margin: 0;

}

.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    width: 150px;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 130px;
    height: 80px;
    margin: 10px;
    object-fit: cover;
}
.bottomNavButton{
    position: fixed;
    top: 0px;
    right: 0px;
    width: 50px;
    height: 150px;
}
.bottomNavButton div .libao{
    width: 45px;
    height: 45px;
    margin-right: 35px;
    margin-top: 35px;
    animation: mysacal 3s infinite;
}
.wechat-share{
    position: relative;
}
.share-bless{
    position: absolute;
    width: 70px;
    height: 37px;
    line-height: 23px;
    font-size: 12px;
    background-image: url('../../public/sharezhufu.png');
    background-size: contain;
    background-repeat: no-repeat;
    right: 5px;
    bottom: 15px;
}
@keyframes mysacal {
    0% {
      transform: scale(1); 
    }
    25% {
      transform: scale(1.3); 
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.3);
    }
  }
 
  @-webkit-keyframes mysacal {
    0% {
      transform: scale(1);
    }
    25% {
      transform: scale(1.3);
    }
    50% {
      transform: scale(1);
    }
    75% {
      transform: scale(1.3);
    }
  }
.bottomNavButton > div {
    width: 50px;
    height: 50px;
    display:flex;
    justify-content:center;
    align-items: center;
    cursor:pointer;
}
.bottomNav{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    left: 0;
}
.bottomNav > .sceneNav{
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    justify-content: space-between;
    background-color: white;
    border-radius: 25px;
    padding-left: 25px;
    padding-right: 25px;
}
.bottomNav > .sceneNav > div {
    width: 50px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 9px;
    cursor: pointer;
    color: rgb(38,38,38);
    cursor: pointer;
}
.bottomNav > .sceneNav > div > span{
    font-size: 12px;
}
.bottomNav > .bottomImgList{
    height: 100px;
    width: 100%;
    background-color:rgba(0,0,0,0.4)
}


.bottomWander{
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    bottom: 0;
    right: 0;
    left: 0;
}
.bottomWander > .sceneNav{
    display: flex;
    align-items: center;
    height: 50px;
    margin-bottom: 20px;
    justify-content: space-between;
    background-color: rgba(255,255,255,0.1);
    border-radius: 25px;
    padding-left: 25px;
    padding-right: 25px;
}

.bottomWander > .sceneNav > div {
    width: 75px;
    height: 32px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-top: 9px;
    padding-bottom: 9px;
    cursor: pointer;
    color: rgb(38,38,38);
    cursor: pointer;
}

.bottomWander > .sceneNav > div > i{
    background-color: rgba(255,255,255,0.15);
    color: rgba(0,0,0,0.9);
}
.bottomWander > .sceneNav > div > span{
    font-size: 12px;
    color: rgba(0,0,0,0.9);
}


@media screen and (max-width: 768px) {
}
@media screen and (min-width: 768px) {
}

/* CDN 服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3013934 */
  src: url('//at.alicdn.com/t/c/font_3013934_3czwjeekt4m.woff2?t=1662466650465') format('woff2'),
       url('//at.alicdn.com/t/c/font_3013934_3czwjeekt4m.woff?t=1662466650465') format('woff'),
       url('//at.alicdn.com/t/c/font_3013934_3czwjeekt4m.ttf?t=1662466650465') format('truetype');
}
.iconfont{
    font-family:"iconfont" !important;
    font-size:16px;font-style:normal;
    -webkit-font-smoothing: antialiased;
    -webkit-text-stroke-width: 0.2px;
    -moz-osx-font-smoothing: grayscale;
    color: rgb(38,38,38);
    background: rgba(255,255,255,0.8);
    padding: 5px !important;
}
/* hover#09c9ff */
</style>
