const api = {
    getCommentList: 'https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getCommentList',
    getOpenid: 'https://ky3d.com/addons/yb_guanwang/core/index.php/api/index/getOpenid',
    sendComment:'/wapapi/addons/yb_guanwang/core/index.php/api/index/sendComment',
    getExhibitionTitle:'/wapapi/addons/yb_guanwang/core/index.php/api/index/getExhibitionTitle',
    recordData:'/wapapi/addons/yb_guanwang/core/index.php/api/index/recordData',
    getExhiUlikeInfo:'/devT/addons/yb_guanwang/core/index.php/api/wechatoa/getExhiUlikeInfo',
    onUlike:'/devT/addons/yb_guanwang/core/index.php/api/wechatoa/onUlike',
    onUlocate:'/devT/addons/yb_guanwang/core/index.php/api/wechatoa/onUlocate',
    addExhiVisit:'/wapapi/addons/yb_guanwang/core/index.php/api/wechatoa/addExhiVisit',
}

  
import axios from 'axios'

export function getCommentList(parameter) {
    return axios.get(api.getCommentList, {
        params: parameter
    })
}

export function getOpenid(parameter) {
    return axios.get(api.getOpenid, {
        params: parameter
    })
}
  
export function sendComment(parameter) {
    return axios.post(api.sendComment, parameter)
}

export function getExhibitionTitle(parameter) {
    return axios.post(api.getExhibitionTitle, parameter)
}

export function recordData(parameter) {
    return axios.post(api.recordData, parameter)
}

export function getExhiUlikeInfo(parameter) {
    return axios.post(api.getExhiUlikeInfo, parameter)
}
export function onUlike(parameter) {
    return axios.post(api.onUlike, parameter)
}

export function onUlocate(parameter) {
    return axios.post(api.onUlocate, parameter)
}

export function addExhiVisit(parameter) {
    return axios.post(api.addExhiVisit, parameter)
}